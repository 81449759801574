//agrege la restriccion de login a las rutas de las apps
import i18n from "@/libs/i18n";
import PageEditor from "@/views/pages/pages/PageEditor.vue";
import PageManager from "@/views/pages/pages/PageManager.vue";

export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/jeanrosas/list',
    name: 'apps-jeanrosas-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/transfers/transfers-list/TransfersList.vue'),
  },

  {
    path: '/apps/transfers/list',
    name: 'apps-transfers-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/transfers/transfers-list/TransfersList.vue'),
  },
  {
    path: '/apps/banks/list',
    name: 'apps-banks-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/banks/banks-list/BanksList.vue'),
  },
  {
    path: '/apps/users/wallet',
    name: 'apps-users-wallet',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-edit/UsersWallet.vue'),
  },
  {
    path: '/apps/users/wallet-recharge/:username',
    name: 'apps-users-wallet-recharge',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-edit/UsersWalletRecharge.vue'),
  },

  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/:id',
    name: 'apps-users-show',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/ViewUser')
  },
  {
    path: '/apps/users/bitacora/:id',
    name: 'apps-users-bitacora',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-list/BitacoraUser.vue'),
  },
  {
    path: '/apps/users/tree',
    name: 'apps-users-tree',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-list/UsersTree.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // *===============================================---*
  // *--------- CLIENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/clients/list',
    name: 'apps-clients-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/clients/clients-list/ClientsList.vue'),
  },
  {
    path: '/apps/clients/view/:id',
    name: 'apps-clients-view',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/clients/clients-view/ClientsView.vue'),
  },
  {
    path: '/apps/clients/edit/:id',
    name: 'apps-clients-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/clients/clients-edit/ClientsEdit.vue'),
  },

  // *===============================================---*
  // *--------- MODULES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/modules',
    name: 'modules-list',
    meta: {
      login: true,
      pageTitle: 'Modules',
      breadcrumb: [
        {
          text: 'List of Modules',
          active: true,
        },
      ],
    },
    component: () => import('@/views/pages/modules'),
  },
  {
    path: '/apps/modules/view/:id',
    name: 'apps-modules-view',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/modules/modules-view/ModulesView.vue'),
  },
  {
    path: '/apps/modules/edit/:id',
    name: 'apps-modules-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/modules/modules-edit/ModulesEdit.vue'),
  },

  // *===============================================---*
  // *--------- ROLES   MODIFICADO---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/roles',
    name: 'list-roles',
    component: () => import('@/views/pages/Roless'),
    meta: {
      login: true,
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'List of Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/roles/:id/permissions',
    name: 'permissions-roles',
    component: () => import('@/views/pages/Roless/Permissions'),
    meta: {
      login: true,
      pageTitle: 'Permissions',
      breadcrumb: [
        {
          text: 'List of Roles',
          to: '/apps/roles',
        },
        {
          text: 'Permissions',
          active: true,
        }
      ],
    },
  },

  // *===============================================---*
  // *--------- ROLES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/roles/list',
    name: 'apps-roles-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/roles/roles-list/RolesList.vue'),
  },
  {
    path: '/apps/roles/view/:id',
    name: 'apps-roles-view',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/roles/roles-view/RolesView.vue'),
  },
  {
    path: '/apps/roles/edit/:id',
    name: 'apps-roles-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/roles/roles-edit/RolesEdit.vue'),
  },


  // *===============================================---*
  // *--------- Whitelabels ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/whitelabel/list',
    name: 'apps-whitelabels-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/whitelabel'),
  },
  {
    path: '/apps/whitelabel/view/:id',
    name: 'apps-whitelabels-view',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/whitelabel/whitelabel-view/WhitelabelView.vue'),
  },
  {
    path: '/apps/whitelabel/detail/:id',
    name: 'apps-whitelabel-detail',
    meta: {
      login: true,
    },
    // component: () => import('@/views/pages/whitelabel/whitelabel-edit/WhitelabelEdit.vue'),
    component: () => import('@/views/pages/whitelabel/WhitelabelListAddNew.vue'),
  },
  {
    path: '/apps/whitelabel/edit/:id',
    name: 'apps-whitelabels-edit',
    meta: {
      login: true,
    },
    // component: () => import('@/views/pages/whitelabel/whitelabel-edit/WhitelabelEdit.vue'),
    component: () => import('@/views/pages/whitelabel/WhitelabelListAddNew.vue'),
  },
  {
    path: '/apps/whitelabel/menu/:id',
    name: 'apps-whitelabels-menu-add',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/whitelabel/whitelabel-menu/WhitelabelMenu.vue'),
  },

  // *===============================================---*
  // *--------- Banners ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/banners/list',
    name: 'apps-banners-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/BannerManagement/external/banners-new/banners-list/BannersList.vue'),
  },
  {
    path: '/apps/banner/view/:id',
    name: 'apps-banners-view',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/BannerManagement/external/banners/banners-view/BannersView.vue'),
  },
  {
    path: '/apps/banner/edit/:id',
    name: 'apps-banners-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/BannerManagement/external/banners/banners-edit/BannersEdit.vue'),
  },

  // *===============================================---*
  // *--------- Lobby ---- ---------------------------------------*
  // *
  {
    path: '/apps/lobby',
    name: 'lobby',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-list/index.vue'),
  },

  {
    path: '/apps/lobbys/edit/:id',
    name: 'apps-lobbys-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-form/LobbyForm.vue'),
  },

  {
    path: '/apps/lobby/edit/:id',
    name: 'apps-lobby-form',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-form/LobbyForm.vue'),
  },

  {
    path: '/apostala/lobby/edit/:id',
    name: 'ap-lobby-form',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/Apostala/config/PageEdit/LobbyManagement/index.vue'),
  },

  {
    path: '/kb/lobby/edit/:id',
    name: 'kb-lobby-form',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/KB/config/PageEdit/LobbyManagement/index.vue'),
  },

  {
    path: '/kb/lobby/new',
    name: 'kb-lobby-form-create',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/KB/config/PageEdit/LobbyManagement/index.vue'),
  },


  {
    path: '/apps/lobby/new',
    name: 'ap-lobby-form-create',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/Apostala/config/PageEdit/LobbyManagement/index.vue'),
  },

  {
    path: '/god-money/lobby/new',
    name: 'gm-lobby-form-create',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/GodMoney/config/PageEdit/LobbyManagement/index.vue'),
  },

  {
    path: '/god-money/lobby/edit/:id',
    name: 'gm-lobby-form',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/GodMoney/config/PageEdit/LobbyManagement/index.vue'),
  },

  {
    path: '/apps/lobby/new',
    name: 'apps-lobby-form-create',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-form/LobbyForm.vue'),
  },

  // *===============================================---*
  // *--------- Headers ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/headers/list',
    name: 'apps-headers-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/headers/headers-list/HeadersList.vue'),
  },
  {
    path: '/apps/headers/store/:id?',
    name: 'apps-headers-sotre',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/headers/header-store/HeaderStore.vue'),
  },
  // {
  //   path: '/apps/header/view/:id',
  //   name: 'apps-headers-view',
  //   component: () => import('@/views/pages/headers/headers-view/HeadersView.vue'),
  // },
  {
    path: '/apps/header/edit/:id',
    name: 'apps-headers-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/headers/headers-edit/HeadersEdit.vue'),
  },

  // *===============================================---*
  // *--------- Footers ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/footers/store/:id?',
    name: 'apps-footers-store',
    meta: {
      login: true,
      // pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'footers',
          to: '/apps/footers/list',
        },
        {
          text: i18n.t('new_footer'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/FooterManagement/external/footers/footers-store/footersStore.vue'),
  },
  {
    path: '/apps/footers/store/app-template/:id?',
    name: 'apps-footers-store-app-template',
    meta: {
      login: true,
      // pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'footers',
          to: '/apps/footers/list',
        },
        {
          text: i18n.t('new_footer'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/FooterManagement/external/footers-new/footers-store/footersStore.vue'),
  },
  // {
  //   path: '/apps/footer/view/:id',
  //   name: 'apps-footers-view',
  //   component: () => import('@/views/pages/footers/footers-view/FootersView.vue'),
  // },
  {
    path: '/apps/footer/edit/:id',
    name: 'apps-footers-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/FooterManagement/external/footers/footers-edit/FootersEdit.vue'),
  },

  // *===============================================---*
  // *--------- Homepages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/homepages/store',
    name: 'apps-homepages-store',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/HomePageManagement/external/homePages/HomePageStore.vue'),
  },
  {
    path: '/apps/homePage/edit/:id',
    name: 'apps-homepages-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/config/PageEdit/HomePageManagement/external/homePages/HomePageStore.vue'),
  },

  // *===============================================---*
  // *--------- Menu page ------------------------------*
  // *===============================================---*
  {
    path: '/apps/menus/list',
    name: 'apps-menus-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/menu/menu-list/menusList.vue'),
  },
  {
    path: '/apps/menus/store/:id?',
    name: 'apps-menus-store',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/menu/menu-store/menusStore.vue'),
  },
  // {
  //   path: '/apps/menus/view/:id',
  //   name: 'apps-menus-view',
  //   component: () => import('@/views/pages/menu/menu-view/menusView.vue'),
  // },
  {
    path: '/apps/menus/edit/:id',
    name: 'apps-menus-edit',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/menu/menu-edit/menusEdit.vue'),
  },
  // *===============================================---*
  // *--------- REPORTS ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/reports/players',
    name: 'apps-reports-player',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/player-reports/PlayerReport.vue'),
  },
  {
    path: '/apps/reports/players-new',
    name: 'apps-reports-player-new',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/player-reports/PlayerReportNew.vue'),
  },
  {
    path: '/apps/reports/gaming_performance',
    name: 'apps-reports-gaming_performance',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/games/GamingPerformanceReport.vue'),
  },
  {
    path: '/apps/reports/player_session',
    name: 'apps-reports-player_session',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/player-reports/PlayerSessionReport.vue'),
  },
  {
    path: '/apps/reports/players-brands',
    name: 'apps-reports-player-brands',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/player-reports/PlayerBrandsReport.vue'),
  },
  {
    path: '/apps/reports/players-brands-lite',
    name: 'apps-reports-player-brands-lite',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/player-reports/PlayerBrandsReportLite.vue'),
  },
  {
    path: '/apps/reports/daily',
    name: 'apps-reports-daily',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/DailyReport.vue'),
  },
  {
    path: '/apps/reports/global',
    name: 'apps-reports-global',
    meta: {
      login: true,
    },
    //component: () => import('@/views/pages/reports/HistoryReport.vue'),
    component: () => import('@/views/pages/reports/GlobalReport.vue'),
    // component: () => import('@/views/pages/reports/GlobalReportOld.vue'),
  },
  {
    path: '/apps/reports/agentsByBrands',
    name: 'apps-agents-by-brands-reports',
    meta: {
      login: true,
    },
    //component: () => import('@/views/pages/reports/HistoryReport.vue'),
    component: () => import('@/views/pages/reports/agents/AgentsByBrands.vue'),
    // component: () => import('@/views/pages/reports/GlobalReportOld.vue'),
  },
  {
    path: '/apps/reports/agentsByBrandsLite',
    name: 'apps-agents-by-brands-reports-lite',
    meta: {
      login: true,
    },
    //component: () => import('@/views/pages/reports/HistoryReport.vue'),
    component: () => import('@/views/pages/reports/agents/AgentsByBrandsLite.vue'),
    // component: () => import('@/views/pages/reports/GlobalReportOld.vue'),
  },
  {
    path: '/apps/reports/payment-methods',
    name: 'payment-methods',
    meta: {
      login: true,
    },
    //component: () => import('@/views/pages/reports/HistoryReport.vue'),
    component: () => import('@/views/pages/reports/payment-methods/'),
    // component: () => import('@/views/pages/reports/GlobalReportOld.vue'),
  },
  {
    path: '/apps/games/list',
    name: 'games-list',
    meta: {
      login: true,
    },
    //component: () => import('@/views/pages/reports/HistoryReport.vue'),
    component: () => import('@/views/pages/games/games-list/gamesList.vue'),
    // component: () => import('@/views/pages/reports/GlobalReportOld.vue'),
  },
  {
    path: '/apps/reports/globalr',
    name: 'apps-reports-global-old',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/GlobalReport.vue'),
  },
  {
    path: '/apps/reports/rechargeRetirement',
    name: 'apps-reports-rechargeRetirement',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/rechargeRetirement.vue'),
  },
  {
    path: '/apps/reports/exclusions',
    name: 'apps-exclusions-report',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/exclusion/Exclusions.vue'),
  },
  {
    path: '/apps/reports/big-prizes',
    name: 'apps-big-prizes-report',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/big-prizes/BigPrizesReport.vue'),
  },
  {
    path: '/apps/reports/userHistory',
    name: 'apps-reports-userHistory',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/userHistories.vue'),
    //component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/reports/products/profitProducts',
    name: 'apps-reports-profitProducts',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/products/profitProducts.vue'),
  },
  {
    path: '/apps/reports/products/detailProfit',
    name: 'apps-reports-detailProfit',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/products/detailProfit.vue'),
  },
  {
    path: '/apps/reports/products/mostPlayed',
    name: 'apps-reports-mostPlayed',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/products/mostPlayed.vue'),
  },
  {
    path: '/apps/reports/products/byGames',
    name: 'apps-reports-byGames',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/products/byGames.vue'),
  },
  // reports brands
  {
    path: '/apps/reports/brands/profit',
    name: 'apps-reports-profitBrands',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/brands/profit.vue'),
  },
  {
    path: '/apps/reports/brands/mostPlayed',
    name: 'apps-reports-brands-mostPlayed',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/brands/mostPlayed.vue'),
  },
  {
    path: '/apps/reports/brands/byGames',
    name: 'apps-reports-brands-byGames',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/brands/byGames.vue'),
  },
  {
    path: '/apps/reports/horses/betsPlaced',
    name: 'apps-reports-betsPlaced',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/horses/betsPlaced.vue'),
  },
  {
  path: '/apps/reports/horses/utilities',
  name: 'apps-reports-utilities',
  meta: {
    login: true,
  },
  component: () => import('@/views/pages/reports/horses/utilities.vue'),
  },
  {
    path: '/apps/reports/sports/betsPlaced',
    name: 'apps-reports-sports',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/sports/betsPlaced.vue'),
  },
  {
    path: '/apps/reports/sports/betsPlacedTmp',
    name: 'apps-reports-sports-tmp',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/sports/betsPlacedTmp.vue'),
  },
  {
    path: '/apps/reports/sports/betsPlacedAb',
    name: 'apps-reports-sports-ab',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/sports/betsPlacedAb.vue'),
  },
  {
    path: '/apps/reports/ticket-sports-live/betsPlaced',
    name: 'apps-reports-ticket-sports-live',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/ticket-sports-live/betsPlaced.vue'),
  },
  {
    path: '/apps/reports/sports/betsPlaced/:id/detail',
    name: 'apps-sports-detail',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/sports/detailBetsPlaced.vue'),
  },
  {
    path: '/apps/reports/sports/ticket-sports-live/liveBetting',
    name: 'apps-reports-liveBetting',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/ticket-sports-live/liveBetting/index.vue'),
  },
  {
    path: '/apps/reports/sports/ticket-sports-live/liveBetting/:id/:userId/:status/detail',
    name: 'apps-reports-liveBetting-detail',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/ticket-sports-live/liveBetting/detail.vue'),
  },
  {
    path: '/apps/reports/ticket-sports-live/betsPlaced/:id/detail',
    name: 'apps-ticket-sports-live-detail',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/ticket-sports-live/detailSw3.vue'),
  },
  {
    path: '/apps/reports/sports/betsPlaced/:id/detailTmp',
    name: 'apps-sports-detail-tmp',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/sports/detailBetsPlacedTmp.vue'),
  },
  {
    path: '/apps/reports/currencies/accumulated',
    name: 'apps-currencies-accumulated',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/currencies/accumulated.vue'),
  },
  {
    path: '/apps/reports/currencies/products',
    name: 'apps-currencies-products',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/reports/currencies/product.vue'),
  },
  {
    path: '/views/audit',
    name: 'modules-audit',
    component: () => import('@/views/pages/AuditModule/index.vue'),
},
  {
    path: '/apps/messages/list/:id?',
    name: 'apps-messages-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/messageModule/messageModulePage'),
  },
  {
    path: '/apps/exclusion/list/:id?',
    name: 'apps-exclusion-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/exclusionList/index.vue'),
  },

  {
    path: '/apps/revocation/list/:id?',
    name: 'apps-revocation-list',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/revocation/index.vue'),
  },
  {
    path: '/apps/template/app-template/preview/:id?',
    name: 'app-template-preview',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/App/index.vue'),
  },
  {
    path: '/apps/template/apostala-template/preview/:id?',
    name: 'apostala-template-preview',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/Apostala/index.vue'),
  },
  {
    path: '/apps/template/kingboxplus-template/preview/:id?',
    name: 'kingboxplus-template-preview',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/KB/index.vue'),
  },
  {
    path: '/apps/template/godmoney-template/preview/:id?',
    name: 'godmoney-template-preview',
    meta: {
      login: true,
    },
    component: () => import('@/views/components/whitelabel-templates/GodMoney/index.vue'),
  },
  {
    path: '/apps/template/whitelabel',
    name: 'default-template-index',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/templates/template-default/index.vue'),
  },
  {
    path: '/apps/template/new-template',
    name: 'new-template-index',
    meta: {
      login: true,
    },
    component: () => import('@/views/pages/templates/templates-create/index.vue'),
  },
  {
    path: '/apps/pages/:slug',
    name: 'Page',
    meta: {
      login: true,
    },
    props: true,
    component: () => import('@/views/pages/pages/PageRender.vue'),
  },
  {
    path: '/apps/dynamic-pages/manager/',
    name: 'PageManager',
    meta: {
      login: true,
    },
    component: PageManager,
  },
  {
    path: '/apps/dynamic-pages/editor/:mode/:slug',
    name: 'PageEditor',
    meta: {
      login: true,
    },
    props: true,
    component: PageEditor,
  },
]
